import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Privacy Policy / Datenschutzerklärung" />
      <h1>Privacy Policy / Datenschutzerklärung</h1>
      <h2>WikiWars</h2>
      <p>Aktualisiert 11.03.2022</p>
      <p>
        Promopage:{" "}
        <a href="https://wikiwars.palmomedia.de/" target="_blank">
          wikiwars.palmomedia.de
        </a>
      </p>
      <p>
        Diese Datenschutzrichtlinie beschreibt die Richtlinien und Verfahren.
        Durch die Nutzung des Dienstes stimmst du den in diesem Dokument
        enthaltenen Informationen zu.
      </p>
      <h2>Persönliche Daten</h2>
      <p>
        Die APP erhebt keine weiteren personenbezogenen Daten außer dem Namen
        des Spielers.
      </p>
      <h2>Nutzungsdaten</h2>
      <p>
        Die APP sammelt keine weiteren Nutzungsdaten außer den Klicks bis zum
        Ziel Artikel.
      </p>
      <h2>Verwendung deiner personenbezogenen Daten</h2>
      <p>
        Die Daten werden nur auf dein Ersuchen hin verarbeitet und
        weitergeleitet.
      </p>
      <h2>Aufbewahrung deiner personenbezogenen Daten</h2>
      <p>Die APP speichert die Daten bis du die Abmelde-Funktion nutzt.</p>
      <h2>Offenlegung deiner personenbezogenen Daten</h2>
      <p>Wird in keinem Fall vorgenommen.</p>
      <h2>Datensicherheit</h2>
      <p>Die Übermittlung der Daten auf den Server erfolgt via SSL (HTTPS).</p>
      <h2>Datenschutz für Kinder</h2>
      <p>Der Service richtet sich nicht an Personen unter 13 Jahren.</p>
      <h2>Änderungen dieser Datenschutzrichtlinie</h2>
      <p>
        Ich kann diese Datenschutzrichtlinie bei Bedarf aktualisieren. Ich
        werden dich über alle Änderungen informieren, indem ich die neue
        Datenschutzrichtlinie auf dieser Seite veröffentliche. Änderungen dieser
        Datenschutzrichtlinie werden wirksam, wenn sie auf dieser Seite
        veröffentlicht werden.
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
